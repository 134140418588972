<script setup lang="ts">
import { removeToken, removeUserInfo } from '@/utils/auth';
import { useRoute, useRouter } from 'vue-router';
import ChangeLanguage from '@/components/changeLanguage/ChangeLanguage.vue';
import FeedBack from '@/components/feedBack/FeedBack.vue';
import { ref } from 'vue';
import i18n from '@/lang';
import { initUserInfo, useUserInfoStore } from '@/stores/userInfo';
import UserLogin from '@/views/UserLogin.vue';
import { whiteList } from '@/router';
import { apiClick, featuresClick } from './LayoutMain';
const userInfoStore = useUserInfoStore();
const router = useRouter();
const route = useRoute();

const USERINFO_LOGOUT = 'logout';

const onUserInfoCommand = (command: string) => {
  if (command === USERINFO_LOGOUT) {
    removeToken();
    removeUserInfo();
    userInfoStore.userInfo = initUserInfo();
    router.push({
      path: '/dashboard',
      query: {
        redirect: route.fullPath
      }
    });
  }
};

const feedBackRef = ref();
const onFeedbackClick = () => {
  feedBackRef.value?.onFeedbackButtonClick();
};

const onHelpCenterClick = () => {
  const lang = i18n.global.locale.value.toLocaleLowerCase();
  window.open(`${window.location.origin}/helpcenter/${lang}/`);
};

const loginDialogVisible = ref(false);
const onSignInClick = () => {
  loginDialogVisible.value = true;
};
const onDialogClose = () => {
  loginDialogVisible.value = false;
};

const onFeaturesClick = () => {
  featuresClick();
};

const onApiClick = () => {
  apiClick();
};

</script>

<template>
  <div class="layout-header-wrap" :class="{'layout-header-wrap_white-list': whiteList.includes(route.path)}">
    <div id="layout-header" class="layout-header" nav-mode="dark">
      <div class="layout-header-nav" id="inline-nav">
        <div class="layout-header-nav__left">
          <div class="layout-header-nav-logo">
            <img src="../../assets/image/logo.png">
            <span>{{ $t('common.appName') }}</span>
            <span class="beta">
              Beta
            </span>
          </div>
        </div>
        <div class="layout-header-nav__right">
          <div class="layout-header-nav-setting" v-if="userInfoStore.userInfo.token">
            <div class="layout-header-nav-setting-icon pad">
              <span @click="onFeedbackClick" class="feedback">{{ $t('common.feedback.title') }}</span>
            </div>
          </div>
          <div v-if="!userInfoStore.userInfo.token" class="layout-header-nav-setting">
            <div class="layout-header-nav-setting-icon pad">
              <span @click="onFeaturesClick">{{ $t('common.footer.features') }}</span>
            </div>
          </div>
          <div v-if="!userInfoStore.userInfo.token" class="layout-header-nav-setting">
            <div class="layout-header-nav-setting-icon pad">
              <span @click="onApiClick">{{ $t('common.footer.api') }}</span>
            </div>
          </div>
          <div class="layout-header-nav-setting">
            <div class="layout-header-nav-setting-icon pad">
              <span @click="onHelpCenterClick" class="help-center">{{ $t('common.helpCenter.title') }}</span>
            </div>
          </div>
          <div class="layout-header-nav-setting">
            <div class="layout-header-nav-setting-icon pad">
              <div class="icon-language">
                <ChangeLanguage></ChangeLanguage>
              </div>
            </div>
          </div>
          <div class="layout-header-nav-user-info" v-if="userInfoStore.userInfo.token">
            <div class="layout-header-nav-user-info-account">
              <p class="layout-header-nav-user-info-account-name">{{ userInfoStore.userInfo.name }}</p>
            </div>
            <el-dropdown :teleported="false" class="user-info-dropdown" @command="onUserInfoCommand">
              <img :src="userInfoStore.userInfo.icon" alt="">
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :command="USERINFO_LOGOUT">{{ $t('login.logoutBtn') }}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div class="login" v-if="!userInfoStore.userInfo.token">
            <el-button @click="onSignInClick">{{ $t('login.loginBtn') }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FeedBack ref="feedBackRef"></FeedBack>
  <UserLogin :visible="loginDialogVisible" @onDialogClose="onDialogClose"></UserLogin>
</template>

<style lang="scss" scoped src="./LayoutHeader.scss"></style>

import moment from 'moment-timezone';
import _ from 'lodash-es';
import BigNumber from 'bignumber.js';

export function encodeBase64(source: string | object): string {
  let str = '';
  if (typeof source === 'string') {
    str = source;
  } else {
    str = JSON.stringify(source);
  }
  return btoa(encodeURI(str));
}

export function decodeBase64(source: string) {
  const str = decodeURI(atob(source));
  try {
    return JSON.parse(str);
  } catch (error) {
    return str;
  }
}

export function formateDate(date: string | number, withZone: boolean = false, timezone?: string) {
  const zone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (withZone) {
    return moment(date).tz(zone).format('YYYY-MM-DD z');
  } else {
    return moment(date).tz(zone).format('YYYY-MM-DD');
  }
}

export function formateMin(date: string | number, withZone: boolean = false, timezone?: string) {
  const zone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (withZone) {
    return moment(date).tz(zone).format('YYYY-MM-DD hh:mm z');
  } else {
    return moment(date).tz(zone).format('YYYY-MM-DD hh:mm');
  }
}

export function formateTime(date: string | number, withZone: boolean = false, timezone?: string) {
  const zone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (withZone) {
    return moment(date).tz(zone).format('YYYY-MM-DD hh:mm:ss z');
  } else {
    return moment(date).tz(zone).format('YYYY-MM-DD');
  }
}

export function formateDateTimezone(date: string | number, timezone: string) {
  return moment(date).tz(timezone).format('z');
}

export function setPropertyFromTargetObj(source: any, target: any, exclude: Array<string> = []) {
  type Obj = {
    [index: string]: any
  }
  const sourceObj = source as Obj;
  const targetObj = _.cloneDeep(target as Obj);

  for (const i in targetObj) {
    if (sourceObj[i] !== undefined && !exclude.includes(i)) {
      sourceObj[i] = targetObj[i];
    }
  }
}

export const immediateCallTimeoutWithPromise = async(number?: number) => {
  return await new Promise((resolve) => {
    const timeout = setTimeout(async() => {
      clearTimeout(timeout);
      resolve(true);
    }, number || 0);
  });
};

export function sendActivity(send_to_ga: boolean = true, action: string, event_category: string, event_label: string, value?: number, extendData?: {[key: string]: string | boolean}) {
  const euf = (window as any).euf;
  if (!euf) return;
  euf.trace.sendActivity(send_to_ga, action, event_category, event_label, value, extendData);
}

export const changeByte = (byte: number) => {
  const constant_1024 = 1024;
  let size = '';
  if (byte < 1 * constant_1024) {
    // 小于1KB，则转化成B
    size = `${byte.toFixed(2)}B`;
  } else if (byte < 1 * constant_1024 * constant_1024) {
    // 小于1MB，则转化成KB
    size = `${(byte / constant_1024).toFixed(2)}KB`;
  } else if (byte < 1 * constant_1024 * constant_1024 * constant_1024) {
    // 小于1GB，则转化成MB
    size = `${(byte / (constant_1024 * constant_1024)).toFixed(2)}MB`;
  } else if (byte < 1 * constant_1024 * constant_1024 * constant_1024 * constant_1024) {
    // 小于1TB，则转化成GB
    size = `${(byte / (constant_1024 * constant_1024 * constant_1024)).toFixed(2)}GB`;
  } else {
    size = `${(byte / (constant_1024 * constant_1024 * constant_1024 * constant_1024)).toFixed(2)}TB`;
  }

  const sizeStr = `${size}`; // 转成字符串
  const index = sizeStr.indexOf('.'); // 获取小数点处的索引
  const dou = sizeStr.substr(index + 1, 2); // 获取小数点后两位的值
  // eslint-disable-next-line eqeqeq
  if (dou == '00') {
    // 判断后两位是否为00，如果是则删除00
    return sizeStr.replace('.00', '');
  }
  return size;
};

export const getPercentageOfFloatNumber = (floatNumber: number) => {
  if (floatNumber === 1) return 100;
  if (floatNumber === 0) return 0;
  const right = floatNumber.toString().split('.')[1];
  if (right.length === 1) {
    return parseInt(floatNumber.toString().split('.')[1]) * 10;
  } else {
    return parseInt(floatNumber.toString().split('.')[1]);
  }
};

export const addCommasToNumber = (number: number | string) => {
  // 将数字转化为字符串，并去除原有逗号
  const numberStr = String(number).replace(/,/g, '');

  // 使用正则表达式添加逗号分隔
  const formattedNumber = numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return formattedNumber;
};

export const openWindow = (url: string, title: string, w: number, h: number, l: number, t: number) => {
  // Fixes dual-screen position                            Most browsers       Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : (screen as any).left;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : (screen as any).top;

  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

  w = w || width * 0.9;
  h = h || height * 0.9;

  const left = (l || ((width / 2) - (w / 2))) + dualScreenLeft;
  const top = (t || ((height / 2) - (h / 2))) + dualScreenTop;
  const newWindow = window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
  (newWindow as any).document.title = title;
  // Puts focus on the newWindow
  if ((window as any).focus) {
    (newWindow as any).focus();
  }

  return newWindow;
};

export const getQueryObject = (url: string | null) => {
  url = url === null ? window.location.href : url;
  const search = url.substring(url.lastIndexOf('?') + 1);
  const obj = {};
  const reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let val = decodeURIComponent($2);
    val = String(val);
    (obj as any)[name] = val;
    return rs;
  });
  return obj;
};

export const minus = (x: number, y: number) => {
  const _x = new BigNumber(x);
  return _x.minus(y);
};

export const onCommonLoginClick = () => {
  (document.querySelector('#inline-nav > div.layout-header-nav__right > div.login > button') as any).click();
};

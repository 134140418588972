<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import LayoutHeader from './LayoutHeader.vue';
import LayoutSideMenu from './LayoutSideMenu.vue';
import { useInstanceDetailPageStore } from '@/stores/instanceDetailPage';
import { computed } from 'vue';
import { Back } from '@element-plus/icons-vue';
import { useUserInfoStore } from '@/stores/userInfo';
import { whiteList } from '@/router';
import i18n from '@/lang';
import { onCommonLoginClick } from '@/utils';
import { apiClick, featuresClick } from './LayoutMain';
const userInfoStore = useUserInfoStore();
const instanceDetailPageStore = useInstanceDetailPageStore();
const route = useRoute();
const router = useRouter();
const onBackClick = () => {
  const toArithmeticManagementRouteName = ['newService', 'instanceDetail'];
  if (toArithmeticManagementRouteName.includes(route.name as string)) {
    router.push({
      name: 'arithmeticManagement'
    });
  }
};

const excludeBreadcrumbRouteNames = ['arithmeticManagement'];
const showBreadcrumb = computed(() => {
  return !excludeBreadcrumbRouteNames.includes(route.name as string);
});

const lan = computed(() => {
  return i18n.global.locale.value.toLocaleLowerCase();
});

const onTermsOfServiceClick = () => {
  window.open(`https://www.nicegpu.com/helpcenter/${lan.value}/terms-of-service/`);
};
const onPrivacyPolicyClick = () => {
  window.open(`https://www.nicegpu.com/helpcenter/${lan.value}/privacy-policy/`);
};
const onContactUsClick = () => {
  window.open(`${window.location.origin}/contact-us?lan=${lan.value}`);
};
const onAboutUsClick = () => {
  window.open(`https://www.nicegpu.com/helpcenter/${lan.value}/about-us/`);
};

const onSignInClick = () => {
  onCommonLoginClick();
};

const onFeaturesClick = () => {
  featuresClick();
};

const onApiClick = () => {
  apiClick();
};

</script>

<template>
  <div class="layout-main" v-if="route.name" :class="{'layout-main_white-lists': whiteList.includes(route.path)}">
    <div class="layout-header-wrap">
      <layout-header></layout-header>
    </div>
    <div class="layout-content">
      <template v-if="userInfoStore.userInfo.token && !whiteList.includes(route.path)">
        <div class="layout-content-left">
          <layout-side-menu></layout-side-menu>
        </div>
        <div class="layout-content-right">
          <div class="breadcrumb" v-if="showBreadcrumb">
            <el-icon @click="onBackClick" class="breadcrumb-back" v-if="route.meta.backUrl">
              <Back></Back>
            </el-icon>
            <template v-if="route.name === 'instanceDetail'">
              <div class="breadcrumb-container">
                {{ instanceDetailPageStore.instanceName }}
              </div>
            </template>
            <template v-else>
              <div class="breadcrumb-container">
                {{ $t(`common.breadcrumbs.${route.name as string}`) }}
              </div>
            </template>
          </div>
          <div class="page">
            <slot></slot>
          </div>
        </div>
      </template>
      <div v-else class="page">
        <slot></slot>
      </div>
    </div>
    <div class="layout-footer">
      <div class="layout-footer-wraper">
        <div class="left">
          <div class="left-gpu-sumary">
            <div class="summary-logo">
              <img src="../../assets/image/logo.png">
              <span>{{ $t('common.appName') }}</span>
            </div>
            <div class="summary-words">
              {{ $t('common.footer.summary') }}
            </div>
          </div>
          <div class="left-copyright">
            <span>©NiceGPU. All Rights Reserved</span>
          </div>
        </div>
        <div class="right">
          <div class="right-quick-links">
            <div class="quick-links-title">
              {{ $t('common.footer.quickLink') }}
            </div>
            <div class="quick-links-content">
              <span v-if="!userInfoStore.userInfo.token" @click="onFeaturesClick">{{ $t('common.footer.features') }}</span>
              <span v-if="!userInfoStore.userInfo.token" @click="onApiClick">{{ $t('common.footer.api') }}</span>
              <span @click="onTermsOfServiceClick">{{ $t('common.footer.termsOfService') }}</span>
              <span @click="onPrivacyPolicyClick">{{ $t('common.footer.privacyPolicy') }}</span>
              <span @click="onContactUsClick">{{ $t('common.footer.contactUs') }}</span>
              <span @click="onAboutUsClick">{{ $t('common.footer.aboutUs') }}</span>
            </div>
          </div>
          <div class="right-login" v-if="!userInfoStore.userInfo.token">
            <el-button @click="onSignInClick">{{ $t('login.loginBtn') }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped src="./LayoutMain.scss"></style>
